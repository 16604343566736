import * as React from 'react';
import Box from '@mui/material/Box';

const EditMultiLineText = ({ value, editable, inputRef }) => {
    return (
        <Box
            component="div"
            sx={{
                width: '100%',
                height: '500px', // Adjust height as needed
                padding: '16px',
                marginBottom: "16px",
                border: editable ? '1.5px solid #E4E4E4' : '1.5px solid #E4E4E4',
                borderRadius: '10px',
                backgroundColor: 'transparent',
                overflowY: 'auto', // Enable vertical scrolling
                fontFamily: 'Poppins',
                fontSize: '1rem',
                lineHeight: '1.5',
                color: "E4E4E4",
                // whiteSpace: 'pre-wrap', // Preserves line breaks
                '& .formatted-text': {
                    width: '100%',
                },
                '& .formatted-text h1': {
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    margin: '0 0 10px',
                    color: "E4E4E4",
                },
                '& .formatted-text h2': {
                    fontSize: '1.25rem',
                    fontWeight: 'bold',
                    margin: '0 0 8px',
                    color: "E4E4E4",
                },
                '& .formatted-text h3': {
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    margin: '0 0 6px',
                    color: "E4E4E4",
                },
                '& .formatted-text p': {
                    margin: '0 0 10px', // Spacing between paragraphs
                },
                '& .formatted-text pre': {
                    whiteSpace: 'pre-wrap', // Preserve formatting for code blocks
                    backgroundColor: '#f5f5f5',
                    padding: '10px',
                    borderRadius: '4px',
                    margin: '0 0 10px',
                },
                '& .formatted-text a': {
                    color: '#29B1A8',
                    textDecoration: 'none',
                },
                '& .formatted-text a:hover': {
                    textDecoration: 'underline',
                }
            }}
            ref={inputRef}
        >
            {/* Render HTML content safely */}
            <Box
                className="formatted-text"
                dangerouslySetInnerHTML={{ __html: value }}
            />
        </Box>
    );
}

export default EditMultiLineText;



// import * as React from 'react';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';

// // Assuming that value contains HTML content.
// const EditMultiLineText = ({ value, editable, inputRef }) => {
//     return (
//         <Box
//             component="div"
//             sx={{
//                 '& .formatted-text': {
//                     width: '100%',
//                     minHeight: '150px', // Adjust height as needed
//                     padding: '16px',
//                     border: editable ? '1.5px solid #E4E4E4' : 'none',
//                     borderRadius: '4px',
//                     backgroundColor: 'transparent',
//                     fontFamily: 'Poppins',
//                     fontSize: '1rem',
//                     lineHeight: '1.5',
//                     whiteSpace: 'pre-wrap', // Preserves line breaks
//                     overflow: 'auto', // Adds scrollbars if needed
//                 },
//                 '& .formatted-text h1': {
//                     fontSize: '2rem',
//                     fontWeight: 'bold',
//                 },
//                 '& .formatted-text h2': {
//                     fontSize: '1.5rem',
//                     fontWeight: 'bold',
//                 },
//                 '& .formatted-text h3': {
//                     fontSize: '1.25rem',
//                     fontWeight: 'bold',
//                 },
//                 '& .formatted-text p': {
//                     margin: '0 0 16px', // Spacing between paragraphs
//                 },
//                 '& .formatted-text pre': {
//                     whiteSpace: 'pre-wrap', // Preserve formatting for code blocks
//                     backgroundColor: '#f5f5f5',
//                     padding: '10px',
//                     borderRadius: '4px',
//                 },
//                 '& .formatted-text a': {
//                     color: '#29B1A8',
//                     textDecoration: 'none',
//                 },
//                 '& .formatted-text a:hover': {
//                     textDecoration: 'underline',
//                 }
//             }}
//             ref={inputRef}
//         >
//             {/* Render HTML content safely */}
//             <Box
//                 className="formatted-text"
//                 dangerouslySetInnerHTML={{ __html: value }}
//             />
//         </Box>
//     );
// }

// export default EditMultiLineText;




// // import * as React from 'react';
// // import Box from '@mui/material/Box';
// // import TextField from '@mui/material/TextField';

// // const EditMultiLineText = ({ onChange, value, editable, inputRef }) => {
// //     return (
// //         <Box
// //             component="form"
// //             sx={{
// //                 '& .MuiTextField-root': { m: 1, width: '100%' },
// //             }}
// //             noValidate
// //             autoComplete="off">
// //             <TextField
// //                 id="outlined-multiline-static"
// //                 multiline
// //                 rows={18}
// //                 value={value}
// //                 onChange={onChange}
// //                 inputRef={inputRef}
// //                 InputProps={{
// //                     readOnly: !editable,
// //                     sx: {
// //                         '& .MuiOutlinedInput-notchedOutline': {
// //                             border: "1.5px solid #E4E4E4", // Normal border
// //                         },
// //                         '&:hover .MuiOutlinedInput-notchedOutline': {
// //                             border: "1.5px solid #E4E4E4", // Hover border
// //                         },
// //                         '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
// //                             border: "1.5px solid #E4E4E4", // Focused border
// //                         }
// //                     },
// //                 }}
// //                 sx={{
// //                     '& .MuiOutlinedInput-notchedOutline': {
// //                         border: "1.5px solid #E4E4E4", // Normal border
// //                     },
// //                     '&:hover .MuiOutlinedInput-notchedOutline': {
// //                         border: "1.5px solid #E4E4E4", // Hover border
// //                     },
// //                     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
// //                         border: "1.5px solid #E4E4E4", // Focused border
// //                     }
// //                 }}
// //             />
// //         </Box>
// //     );
// // }

// // export default EditMultiLineText;
