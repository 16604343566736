import { TableBody, TableCell, TableRow } from "@mui/material";
import ContactTableCell from "../Common/ContactTableCell";
import CompanyTableCell from "../Common/CompanyTableCell";
import ProjectTableCell from "../Common/ProjectTableCell";
import { formatCurrency } from "../../utils/helper/FormatCurrency";

const cellStyle = {
    whiteSpace: "nowrap",
    borderRight: "1px solid #ddd",
    textAlign: "center",
    fontSize: "13px",
    py: 1,
};

const currencyCellStyle = {
    whiteSpace: "nowrap",
    borderRight: "1px solid #ddd",
    textAlign: "right",
    fontSize: "13px",
    py: 1,
    color: "#FD5707",
};

const cellLinkStyle = {
    ...cellStyle,
    color: "#00A398",
    textDecoration: "underline",
    cursor: "pointer",
};

//   const tableData = {
//     columns: [
//         "Employee ID",
//         "Employee Name",
//         "Employement Type",
//         "Company Name",
//         "Project Code",
//         "Project Name",
//         "Total Hours",
//         "Hourly Rate",
//         "Total Cost",
//         "QRE Potential (%)",
//         "QRE Hours",
//         "QRE Cost",
//     ],
// };

const ProjectsTeamTableBody = ({ data }) => {
    return (
        <TableBody>
            {data.map((row, index) => (
                <TableRow id={index}>
                    <ContactTableCell id={row?.teamMemberId} name={row?.employeeId} />
                    <ContactTableCell id={row?.teamMemberId} name={row?.firstName || row?.lastName ? row?.firstName + " " + row?.lastName : ""} />
                    <TableCell sx={{ ...cellStyle, textAlign: "left" }} id={row?.teamMemberId} > {row?.employementType || ""} </TableCell>
                    <TableCell sx={{ ...cellStyle, textAlign: "left" }} id={row?.teamMemberId}>{row?.employeeTitle}</TableCell>
                    <CompanyTableCell id={row?.companyId} name={row?.companyName} />
                    <ProjectTableCell id={row?.projectId} name={row?.projectCode} />
                    <ProjectTableCell id={row?.projectId} name={row?.projectName} />
                    <TableCell id={row?.teamMemberId} sx={currencyCellStyle}>{row?.totalHours}</TableCell>
                    <TableCell id={row?.teamMemberId} sx={currencyCellStyle}>{row?.hourlyRate ? formatCurrency(row?.hourlyRate, "en-US", row?.currency || "USD") : ""}</TableCell>
                    <TableCell id={row?.teamMemberId} sx={currencyCellStyle}>{row?.totalCost ? formatCurrency(row?.totalCost, "en-US", row?.currency || "USD") : ""}</TableCell>
                    <TableCell id={row?.teamMemberId} sx={currencyCellStyle}>{row?.rndPotential}</TableCell>
                    <TableCell id={row?.teamMemberId} sx={currencyCellStyle}>{row?.rndCredits ? formatCurrency(row?.rndCredits, "en-US", row?.currency || "USD") : ""}</TableCell>
                    <TableCell id={row?.teamMemberId} sx={currencyCellStyle}>{row?.qreCost ? formatCurrency(row?.qreCost, "en-US", row?.currency || "USD") : ""}</TableCell>
                </TableRow>
            ))}
        </TableBody>
    );
};

export default ProjectsTeamTableBody;
