import React from "react";
import {
  Box,
  InputBase,
  InputLabel,
  Tooltip,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";

const styles = {
  inputBase: {
    borderRadius: "20px",
    height: "32px",
    border: "1px solid #E4E4E4",
    pl: 1,
    mb: 0.5,
    width: "200px"
  },
  inputBase2: {
    borderRadius: "20px",
    height: "32px",
    // border: "1px solid #E4E4E4",
    // pl: 1,
    mb: 0.5,
    width: "200px"
  },
  label: {
    color: "#404040",
    fontSize: "14px",
  },
  boxStyle: { display: "flex", flexDirection: "column" },
  menuPaper: {
    maxHeight: "250px",
    width: "200px",
    overflow: "auto",
  },
};

function EditableInput({
  label,
  value,
  onChange,
  disabled,
  display,
  type = "text",
  errors,
  required = false,
  selectOptions = [], // Add this for dropdown options
}) {
  // const style = (value ? styles.inputBase : styles.inputBase2)
  // Conditionally render the Select component for dropdowns
  const renderInput = () => {
    if (type === "select") {
      return (
        <Select
          value={value}
          onChange={onChange}
          disabled={disabled}
          sx={value ? styles.inputBase : styles.inputBase2}
          displayEmpty
          MenuProps={{
            PaperProps: {
              sx: styles.menuPaper,
            },
          }}
        >
          <MenuItem disabled value={null || ""}>
            <span style={{ color: "#00A398" }}>Select {label}</span>
          </MenuItem>
          {selectOptions?.map((option) => (
            <MenuItem key={option?.id} value={option?.id}>
              {option?.name}
            </MenuItem>
          ))}
        </Select>
      );
    } else {
      return (
        <InputBase
          type={type}
          sx={value ? styles.inputBase2 : styles.inputBase}
          value={value}
          disabled={disabled}
          onChange={onChange}
        />
      );
    }
  };

  return (
    <Box sx={{ ...styles.boxStyle, display: display || "auto" }}>
      <InputLabel sx={styles.label}>
        {label.length > 20 ? (
          <Tooltip title={label}>
            <span>{label.substring(0, 20)}...</span>
          </Tooltip>
        ) : (
          label
        )}
        {required && <span style={{ color: "red" }}>*</span>}
      </InputLabel>
      {renderInput()}
      {errors && (
        <Typography sx={{ color: "red", fontSize: "13px" }}>
          {errors}
        </Typography>
      )}
    </Box>
  );
}

export default EditableInput;
