import React, { useEffect, useState } from "react";
import { TableCell, TableHead, TableRow, Checkbox } from "@mui/material";

const headerCellStyle = {
  fontSize: "12px",
  borderRight: "1px solid #ddd",
  whiteSpace: "nowrap",
  px: 0.5,
  py: 1,
  textAlign: "center",
  backgroundColor: "#ececec",
  position: "sticky", // Make the header sticky
  top: 0, // Position it at the top
  zIndex: 1, // Ensure the header is above other content
};

const headerCheckboxStyle = {
  color: "#00A398",
  "&.Mui-checked": { color: "#00A398" },
};

const headerRowStyle = {
  backgroundColor: "rgba(64, 64, 64, 0.1)",
};

function MiniTableHeader2({ tableData, fetchSortParams }) {

  
  return (
    <>
      <TableHead>
        <TableRow sx={headerRowStyle}>
          {tableData.columns?.map((column, index) => (
            <TableCell key={index} sx={index !== 0 ? headerCellStyle : {...headerCellStyle, textAlign: "left", paddingLeft: "1rem",  borderLeft: "1px solid #ddd"}}>
              {column}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

export default MiniTableHeader2;