
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React, { useState } from "react";
import FormatDatetime from "../../../utils/helper/FormatDatetime";

const styles = {
    boxStyle: {
        p: 0.5,
        borderTop: "1px solid #E4E4E4",
    },
    tableStyle: {
        minWidth: 650,
    },
    tableHeadCell: {
        border: "none",
        paddingBottom: 0,
        fontWeight: 600,
        fontSize: "13px",
        textAlign: "center"
    },
    tableHeadCell2: {
        border: "none",
        paddingBottom: 0,
        fontWeight: 600,
        fontSize: "13px",
        paddingLeft: "0%",
    },
    tableRow: {
        "&:last-child td, &:last-child th": { border: 0 },
    },
    tableCell: {
        fontSize: "13px",
        width: "17.5%",
        color: "#29B1A8",
        fontWeight: 400,
        pt: "2px",
        textAlign: "center"
    },
    tableCell1: {
        pl: "0%",
        pt: "1.5px",
    },
    tableCell2: {
        fontSize: "13px",
        width: "17.5%",
        paddingLeft: "-10%",
        color: "#29B1A8",
        fontWeight: 400,
        pt: "2px",
    },
};

function SummaryInfoboxTable({ summaryDetails }) {
    const [modalOpen, setModalOpen] = useState(false);

    const handleModalClose = () => {
        setModalOpen(false);
    };

    return (
        <>
            <Box sx={styles.boxStyle}>
                <TableContainer>
                    <Table sx={styles.tableStyle} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ ...styles.tableHeadCell }}>Technical Summary ID</TableCell>
                                <TableCell sx={{ ...styles.tableHeadCell }}>Project Name</TableCell>
                                <TableCell sx={{ ...styles.tableHeadCell }}>Project ID</TableCell>
                                <TableCell sx={{ ...styles.tableHeadCell }}>Created By</TableCell>
                                <TableCell sx={{ ...styles.tableHeadCell }}>Created On</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={styles.tableRow}>
                                <TableCell sx={styles.tableCell}>
                                    {summaryDetails?.TechnicalSummaryId}
                                </TableCell>
                                <TableCell sx={{ ...styles.tableCell, color: "#FD5707" }}>
                                    {summaryDetails?.projectName}
                                </TableCell>
                                <TableCell sx={{ ...styles.tableCell, color: "#FD5707" }}>
                                    {summaryDetails?.projectCode}
                                </TableCell>
                                <TableCell sx={styles.tableCell}>
                                    {summaryDetails?.createdBy}
                                </TableCell>
                                <TableCell sx={styles.tableCell}>
                                    {FormatDatetime(summaryDetails?.createdTime)}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                </TableContainer>
            </Box>
        </>
    );
}

export default SummaryInfoboxTable;