import { Box, Button, Checkbox, Modal, Paper, Typography } from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import { default as React, useContext, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import * as yup from "yup";
import { BaseURL } from "../../constants/Baseurl";
import { FilterListContext } from "../../context/FiltersListContext";
import { fetchCitiesForStateAndCountry } from "../../utils/helper/FetchCitiesForStateAndCountry ";
import { fetchStatesForCountry } from "../../utils/helper/FetchStatesForCountry ";
import InputBox from "../Common/InputBox";
import SelectBox from "../Common/SelectBox";
import { Authorization_header } from "../../utils/helper/Constant";

const validationSchema = yup.object({
  companyName: yup
    .string("Enter your Account Name")
    .required("Account Name is required"),
});

const styles = {
  paperStyle: {
    boxShadow: "0px 3px 6px #0000001F",
    display: "flex",
    flexDirection: "column",
    gap: 2,
    borderRadius: "20px",
    margin: "auto",
    maxWidth: "90%",
    width: 700,
    maxHeight: "90vh",
    overflowY: "auto",
    scrollbarWidth: "none", // For Firefox
    msOverflowStyle: "none", // For Internet Explorer 10+
    "&::-webkit-scrollbar": {
      display: "none", // For WebKit browsers like Chrome and Safari
    },
  },
  titleStyle: {
    borderBottom: "1px solid #E4E4E4",
    px: 2.5,
    textAlign: "left",
    fontWeight: 600,
    fontSize: "13px",
    py: 1,
  },
  buttonStyle: {
    mr: 1,
    borderRadius: "20px",
    textTransform: "capitalize",
    backgroundColor: "#9F9F9F",
    "&:hover": { backgroundColor: "#9F9F9F" },
  },
  uploadButtonStyle: {
    borderRadius: "20px",
    textTransform: "capitalize",
    backgroundColor: "#00A398",
    "&:hover": { backgroundColor: "#00A398" },
  },
  modalStyle: {
    display: "flex",
  },
  buttonBox: {
    mt: 1,
    display: "flex",
    justifyContent: "flex-end",
    px: 2,
    mb: 2,
  },
  flexBox: {
    display: "flex",
    flexDirection: "column",
    // borderBottom: "1px solid #E4E4E4",
  },
  flexBoxItem: {
    display: "flex",
    justifyContent: "space-between",
    mt: 1,
    gap: 2,
    px: 2,
  },
  label: {
    color: "#404040",
    fontSize: "14px",
  },
  inputBase: {
    borderRadius: "20px",
    height: "40px",
    border: "1px solid #E4E4E4",
    pl: 1,
    mb: 0.5,
  },
  expandMoreIcon: {
    borderRadius: "50%",
    fontSize: "15px",
    backgroundColor: "#404040",
    color: "white",
    mr: 1,
    transition: "transform 0.3s ease",
  },
};

const countries = ["United States", "Canada"];

const CompanyModal = ({ open, handleClose, getData }) => {
  const [isSameAddress, setIsSameAddress] = useState(false);
  const [cities, setCities] = useState([]);
  const [shippingCities, setShippingCities] = useState([]);
  const [countryStates, setCountryStates] = useState([]);
  const [countryShippingStates, setCountryShippingStates] = useState([]);
  const { clientList } = useContext(FilterListContext);
  const addCompanyFormik = useFormik({
    initialValues: {
      companyId: "",
      parentCompanyId: "",
      companyName: "",
      billingAddress: "",
      billingState: "",
      billingCountry: "",
      billingCity: "",
      shippingAddress: "",
      shippingCity: "",
      shippingState: "",
      shippingCountry: "",
      industry: "",
      email: "",
      phone: "",
      employeesCount: "",
      status: "",
      annualRevenue: "",
      SLA: "",
      SLASerialNumber: "",
      SLAExpiration: "",
      noOfLocation: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.parentCompanyId === "") {
        toast.error("Parent Account is required");
        return;
      }
      addCompany(values)
        .then(() => {
          addCompanyFormik.resetForm();
        })
        .catch((error) => {
          console.error("Error adding company:", error);
        });
    },
  });

  const addCompany = async (values) => {
    toast.promise(
      (async () => {
        try {
          const response = await axios.post(
            `${BaseURL}/api/v1/company/${localStorage.getItem(
              "userid"
            )}/create-company`,
            values, Authorization_header()
          );
          if (response.data.success) {
            handleClose();
            getData();
          }
          return response;
        } catch (error) {
          throw error.response
            ? error.response
            : new Error("Network or server error");
        }
      })(),
      {
        loading: "Adding New Account...",
        success: (response) => {
          return response.data.message || "Account added successfully";
        },
        error: (error) => {
          console.error("first error: ", error);
          return error?.data?.error?.message || "Failed to add new Account.";
        },
      }
    );
  };

  const handleCheckboxChange = (event) => {
    setIsSameAddress(event.target.checked);
    if (event.target.checked) {
      const { billingAddress, billingCity, billingState, billingCountry } =
        addCompanyFormik.values;
      addCompanyFormik.setValues({
        ...addCompanyFormik.values,
        shippingAddress: billingAddress,
        shippingCity: billingCity,
        shippingState: billingState,
        shippingCountry: billingCountry,
      });
    } else {
      addCompanyFormik.setValues({
        ...addCompanyFormik.values,
        shippingAddress: "",
        shippingCity: "",
        shippingState: "",
        shippingCountry: "",
      });
    }
  };

  useEffect(() => {
    if (addCompanyFormik.values?.billingCountry) {
      fetchStatesForCountry(addCompanyFormik.values?.billingCountry).then(
        (statesArray) => {
          setCountryStates(statesArray);
        }
      );
    }
  }, [addCompanyFormik.values?.billingCountry]);

  useEffect(() => {
    if (addCompanyFormik.values?.shippingCountry) {
      fetchStatesForCountry(addCompanyFormik.values?.shippingCountry).then(
        (statesArray) => {
          setCountryShippingStates(statesArray);
        }
      );
    }
  }, [addCompanyFormik.values?.shippingCountry]);

  useEffect(() => {
    if (
      addCompanyFormik.values?.billingCountry &&
      addCompanyFormik.values?.billingState
    ) {
      fetchCitiesForStateAndCountry(
        addCompanyFormik.values?.billingCountry,
        addCompanyFormik.values?.billingState
      ).then((citiesArray) => {
        setCities(citiesArray);
      });
    }
  }, [
    addCompanyFormik.values?.billingCountry,
    addCompanyFormik.values?.billingState,
  ]);

  useEffect(() => {
    if (
      addCompanyFormik.values?.shippingCountry &&
      addCompanyFormik.values?.shippingState
    ) {
      fetchCitiesForStateAndCountry(
        addCompanyFormik.values?.shippingCountry,
        addCompanyFormik.values?.shippingState
      ).then((citiesArray) => {
        setShippingCities(citiesArray);
      });
    }
  }, [
    addCompanyFormik.values?.shippingCountry,
    addCompanyFormik.values?.shippingState,
  ]);

  return (
    <Modal open={open} onClose={handleClose} sx={styles.modalStyle}>
      <Paper sx={styles.paperStyle}>
        <Typography variant="h6" sx={styles.titleStyle}>
          Add New Account
        </Typography>
        <form onSubmit={addCompanyFormik.handleSubmit}>
          <Box sx={styles.flexBox}>
            <Typography sx={{ fontWeight: 600, px: 2 }}>General</Typography>
            <Box sx={styles.flexBoxItem}>
              <InputBox
                label="Account Name"
                name="companyName"
                formik={addCompanyFormik}
                required={true}
              />
              <InputBox
                label="Number of Location"
                name="noOfLocation"
                formik={addCompanyFormik}
                type="number"
              />
              <SelectBox
                label="Parent Account"
                name="parentCompanyId"
                formik={addCompanyFormik}
                selectOptions={clientList?.map((item) => ({
                  id: item?.companyId,
                  name: item?.companyName,
                }))}
                required={true}
              />
            </Box>
            <Box sx={{ ...styles.flexBoxItem, mb: 1 }}>
              <InputBox
                label="Industry"
                name="industry"
                formik={addCompanyFormik}
              />
              <InputBox
                label="Phone"
                name="phone"
                formik={addCompanyFormik}
                type="tel"
                inputProps={{ pattern: "[0-9]{10}" }}
              />
              <InputBox
                label="Email Address"
                name="email"
                formik={addCompanyFormik}
                type="email"
                required={true}
              />
            </Box>
            <Box sx={{ ...styles.flexBoxItem, mb: 1 }}>
              <InputBox
                label="Annual Revenue"
                name="annualRevenue"
                formik={addCompanyFormik}
                type="number"
              />
              <InputBox
                label="Employees"
                name="employeesCount"
                formik={addCompanyFormik}
                type="number"
              />
              <InputBox
                label="Status"
                name="status"
                formik={addCompanyFormik}
              />
            </Box>
            <Box sx={{ ...styles.flexBoxItem, mb: 1 }}>
              <InputBox label="SLA" name="SLA" formik={addCompanyFormik} />
              <InputBox
                label="SLA Serial Number"
                name="SLASerialNumber"
                formik={addCompanyFormik}
                type="number"
              />
              <InputBox
                label="SLA Expiration"
                name="SLAExpiration"
                formik={addCompanyFormik}
                type="date"
              />
            </Box>
          </Box>
          {/* Contact */}
          <Box sx={styles.flexBox}>
            <Typography sx={{ fontWeight: 600, px: 2 }}>
              Billing Address
            </Typography>

            <Box sx={styles.flexBoxItem}>
              <SelectBox
                label="Country"
                name="billingCountry"
                formik={addCompanyFormik}
                selectOptions={countries?.map((item) => ({
                  id: item,
                  name: item,
                }))}
              />
              <SelectBox
                label="State"
                name="billingState"
                formik={addCompanyFormik}
                selectOptions={countryStates?.map((item) => ({
                  id: item?.name,
                  name: item?.name,
                }))}
              />
              <SelectBox
                label="City"
                name="billingCity"
                formik={addCompanyFormik}
                selectOptions={cities?.map((item) => ({
                  id: item,
                  name: item,
                }))}
              />
            </Box>
            <Box sx={{ ...styles.flexBoxItem, mb: 1, justifyContent: "left" }}>
              <InputBox
                label="Address Line"
                name="billingAddress"
                formik={addCompanyFormik}
              />

              {/* <InputBox label="Zip Code" name="billingZipCode" formik={addCompanyFormik} /> */}
            </Box>
          </Box>
          <Box sx={styles.flexBox}>
            <Typography sx={{ fontWeight: 600, px: 2 }}>
              Shipping Address
            </Typography>
            <Typography sx={{ display: "flex", alignItems: "center", ml: 1 }}>
              <Checkbox
                checked={isSameAddress}
                onChange={handleCheckboxChange}
                sx={{ color: "#00A398", "&.Mui-checked": { color: "#00A398" } }}
              />{" "}
              Same as Billing Address
            </Typography>
            <Box sx={styles.flexBoxItem}>
              <SelectBox
                label="Country"
                name="shippingCountry"
                formik={addCompanyFormik}
                selectOptions={countries?.map((item) => ({
                  id: item,
                  name: item,
                }))}
              />
              <SelectBox
                label="State"
                name="shippingState"
                formik={addCompanyFormik}
                selectOptions={countryShippingStates?.map((item) => ({
                  id: item?.name,
                  name: item?.name,
                }))}
              />
              <SelectBox
                label="City"
                name="shippingCity"
                formik={addCompanyFormik}
                selectOptions={shippingCities?.map((item) => ({
                  id: item,
                  name: item,
                }))}
              />
            </Box>
            <Box sx={{ ...styles.flexBoxItem, mb: 1, justifyContent: "left" }}>
              <InputBox
                label="Address Line"
                name="shippingAddress"
                formik={addCompanyFormik}
              />
              {/* <InputBox label="Zip Code" name="shippingZipCode" formik={addCompanyFormik} /> */}
            </Box>
          </Box>

          <Box sx={styles.buttonBox}>
            <Button
              variant="contained"
              sx={styles.buttonStyle}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={styles.uploadButtonStyle}
            >
              Add Account
            </Button>
          </Box>
        </form>
        <Toaster />
      </Paper>
    </Modal>
  );
};

export default CompanyModal;
