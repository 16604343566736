export const titles = [
  "CTO",
  "President",
  "VP",
  "Director",
  "Manager",
  "Team Lead",
  "Senior Engineer",
  "Engineer",
  "Consultant",
  "Intern",
];
