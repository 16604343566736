import {
    Box,
    Accordion,
    AccordionDetails,
    Typography,
    Drawer,
    FormControlLabel,
    Checkbox,
    Collapse,
    TextField,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { BaseURL } from "../../constants/Baseurl";
import ActionButton from "../FilterComponents/ActionButton";
import { Authorization_header } from "../../utils/helper/Constant";
import StatusFilter from "./StatusFilter";
import { useFormik } from "formik";
import { ProjectContext } from "../../context/ProjectContext";
import SentToFilters from "./SentToFilters";
import TeamMemberSelector from "./TeamMemberSelector";
import ProjectRolesSelector from "./ProjectRolesSelector";
import zIndex from "@mui/material/styles/zIndex";

const triangleStyle = {
    display: 'inline-block',
    width: 0,
    height: 0,
    marginTop: "5px",
    marginRight: '10px',
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderBottom: '12px solid black',
    transition: 'transform 0.3s ease',
};

const styles = {
    drawerPaper: {
        "& .MuiDrawer-paper": {
            height: "37%",
            display: "flex",
            flexDirection: "column",
            marginTop: "340px",
            marginLeft: "20px",
            borderBottom: "1px solid #E4E4E4",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            borderLeft: "1px solid #E4E4E4",
        },
    },
    drawerContainer: {
        display: "flex",
        flexDirection: "column",
        flex: 10,
        marginTop: "-0%",
        width: "17rem"
    },
    header: {
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #E4E4E4",
        borderTop: "1px solid #E4E4E4",
        px: 2,
        height: "45px",
        justifyContent: "space-between",
        backgroundColor: "#ececec",
        position: "sticky",
        backgroundColor: "#ececec",
        px: 0.5,
        py: 1,
        zIndex: 1,
    },
    title: {
        fontWeight: "500",
        textTransform: "capitalize",
        marginRight: '-2px',
        color: 'black',
        fontSize: '16px',
        position: "sticky",
        backgroundColor: "#ececec",
        px: 0.5,
        py: 1,
        zIndex: 1,
    },
    closeButton: {
        color: "#9F9F9F",
        "&:hover": { color: "#FD5707" },
        marginRight: "-15px"
    },
    accordion: {
        flex: 1,
        overflow: 'auto',
        backgroundColor: 'transparent',
    },
    accordionSummary: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        '&:hover': { backgroundColor: '#03A69B1A' },
        padding: '10px',
        marginTop: "-20px"
    },
    accordionDetails: {
        overflowX: 'hidden',
    },
    footer: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "16px",
        borderTop: "1px solid #E4E4E4",
        marginTop: "1px",
        gap: 1,
    },
    textField: {
        fontSize: '0.82rem',
        padding: '2px 0px',
        height: '32px',
        width: "120px",
        borderRadius: "20px",
    },
    applyButton: {
        color: "#00A398",
    },
    clearButton: {
        color: "#9F9F9F",
    },
    searchBox: {
        mt: 1,
        alignItems: "center",
        display: "flex",
        p: 1,
        pl: 2,
        width: "115%"
    },
    inputBase: {
        borderRadius: "20px",
        width: "80%",
        height: "35px",
        border: "1px solid #9F9F9F",
        mr: 2,
    },
    searchIcon: {
        color: "#9F9F9F",
        ml: "3px",
        mr: "-3px",
        width: "20px",
        height: "20px",
    },
    inputStyle: {
        borderRadius: "20px",
        width: "90%",
        height: "37px",
        border: "1px solid #9F9F9F",
        mt: 2,
        ml: 1.5,
    },
};

function Teamfilters({ open, handleClose, projectId, page, onApplyFilters, fetchTeamData }) {
    const {
        projectFilterState,
        setProjectFilterState,
        clearProjectFilterTrigger,
        setIsProjectFilterApplied,
        triggerProjectClearFilters,
    } = useContext(ProjectContext);
    const [projectRoles, setProjectRoles] = useState(projectFilterState.projectRoles || []);
    const [projectRolesList, setProjectRolesList] = useState([]);
    const [showProjectRoles, setShowProjectRoles] = useState(false);
    const [totalHourlyrate, setTotalHourlyRate] = useState(projectFilterState.totalHourlyrate);
    const [totalExpense, setTotalExpense] = useState(projectFilterState.totalExpense);
    const [rndExpense, setRndExpense] = useState(projectFilterState.rndExpense);
    const [currentPageProjects, setCurrentPageProjects] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [filteredRows, setFilteredRows] = useState([]);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [projectsCountError, setProjectsCountError] = useState('');
    const [projectsCountError2, setProjectsCountError2] = useState('');
    const [projectsCountError3, setProjectsCountError3] = useState('');
    const [positiveNumberError, setPositiveNumberError] = useState('');
    const [positiveNumberError2, setPositiveNumberError2] = useState('');
    const [positiveNumberError3, setPositiveNumberError3] = useState('');
    const [dateError, setDateError] = useState("");
    const [responseDateError, setResponseDateError] = useState("");
    const [showdateError, setShowDateError] = useState(false);
    const [names, setNames] = useState(projectFilterState.names || []);
    const [namesList, setNamesList] = useState([]);
    const [showNames, setShowNames] = useState(false);
    const [showTotalExpense, setShowTotalExpense] = useState(false);
    const [showRnDExpense, setShowRnDExpense] = useState(false);
    const [showTotalHourlyrate, setShowTotalHourlyRate] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const filterFields = [
        { label: 'Member Name' },
        { label: 'Project Role' },
        { label: 'Hourly Rate' },
        // { label: 'Total Hours' },
        { label: 'Total Expense' },
        { label: 'QRE Expense' },
    ];

    const handleSearchInputChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
    };

    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [open]);

    const handleFilterChange = ({ field, scale }) => (event, newValue) => {
        const value = newValue ?? event.target.value;

        setProjectFilterState((prev) => {
            if (scale === "min" || scale === "max") {
                const updatedField = Array.isArray(prev[field]) ? [...prev[field]] : [];
                updatedField[scale === "min" ? 0 : 1] = value;

                // Validation for min and max
                const minValue = parseFloat(updatedField[0]);
                const maxValue = parseFloat(updatedField[1]);

                // Reset error states initially
                if (field === "totalExpense") {
                    setProjectsCountError('');
                    setPositiveNumberError('');
                }
                if (field === "rndExpense") {
                    setProjectsCountError2('');
                    setPositiveNumberError2('');
                }
                if (field === "totalHourlyrate") {
                    setProjectsCountError3('');
                    setPositiveNumberError3('');
                }

                // Check for negative values first
                if (value < 0) {
                    if (field === "totalExpense") {
                        setPositiveNumberError("Only positive num");
                    }
                    if (field === "rndExpense") {
                        setPositiveNumberError2("Only positive num");
                    }
                    if (field === "totalHourlyrate") {
                        setPositiveNumberError3("Only positive num");
                    }
                } else {
                    // Only check min/max validation if both values are non-negative
                    if (minValue && maxValue && minValue > maxValue) {
                        if (field === "totalExpense") {
                            setProjectsCountError("Max should be greater than Min");
                        }
                        if (field === "rndExpense") {
                            setProjectsCountError2("Max should be greater than Min");
                        }
                        if (field === "totalHourlyrate") {
                            setProjectsCountError3("Max should be greater than Min");
                        }
                    }
                }

                return {
                    ...prev,
                    [field]: updatedField
                };
            } else {
                return {
                    ...prev,
                    [field]: value
                };
            }
        });
    };

    const currentData = filteredRows?.slice(
        (currentPageProjects - 1) * itemsPerPage,
        currentPageProjects * itemsPerPage
    );

    while (currentData?.length < itemsPerPage) {
        currentData?.push({});
    }

    useEffect(() => {
        const updatedNameId = namesList?.find((proj) => proj?.name === names)?.namesId;
        const updatedProjectRolesId = projectRolesList?.find((proj) => proj?.name === projectRoles)?.projectRolesId;
        setProjectFilterState(prev => ({
            ...prev,
            namesId: [updatedNameId],
            names,
            projectRolesId: [updatedProjectRolesId],
            projectRoles,
        }));
    }, [names, namesList, projectRoles, projectRolesList]);

    const fetchFilterTeamList = async () => {
        try {
            const url = `${BaseURL}/api/v1/projects/get-team-filter-values?projectId=${projectId}`;
            const response = await axios.get(url, Authorization_header());
            const data = response?.data?.data || {};
            setNamesList(data?.names || []);
            setProjectRolesList(data?.projectRoles || []);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchFilterTeamList();
    }, [projectFilterState.companyIds]);

    useEffect(() => {
        if (clearProjectFilterTrigger) {
            setProjectRoles([]);
            setDateError([]);
            setNames([]);
            setResponseDateError([]);
            setProjectFilterState({
                projectId: [],
                sentBy: [],
                names: [],
                projectRoles: [],
                totalExpense: [0, null],
                rndExpense: [0, null],
                totalHourlyrate: [0, null],
            });
            setShowDateError(false);
            setShowNames(false);
            setShowProjectRoles(false);
            setShowTotalExpense(false);
            setShowRnDExpense(false);
            setShowTotalHourlyRate(false);
            setTotalHourlyRate(false);
            setTotalExpense(false);
            setRndExpense(false);

        }
    }, [clearProjectFilterTrigger]);

    let projectsOptions;
    useEffect(() => {
        const shouldFetchWithFiltersProjects =
            projectFilterState.projectId?.length > 0 ||
            projectFilterState.names?.length > 0 ||
            projectFilterState.projectRoles?.length > 0 ||
            projectFilterState.rndExpense?.length > 0 ||
            projectFilterState.totalHourlyrate?.length > 0 ||
            projectFilterState.totalExpense?.length > 0 ||
            projectFilterState.rndExpense?.length > 0;
        if (shouldFetchWithFiltersProjects) {
            projectsOptions = {
                ...(projectFilterState.projectId?.length > 0 && {
                    projectId: projectFilterState.projectId,
                }),
                ...(projectFilterState.names?.length > 0 && {
                    names: projectFilterState.names,
                }),
                ...(projectFilterState.projectRoles?.length > 0 && {
                    projectRoles: projectFilterState.projectRoles,
                }),
                ...(projectFilterState.totalExpense && {
                    minTotalExpense: projectFilterState.totalExpense[0],
                }),
                ...(projectFilterState.totalExpense && {
                    maxTotalExpense: projectFilterState.totalExpense[1],
                }),
                ...(projectFilterState.rndExpense && {
                    minRnDExpense: projectFilterState.rndExpense[0],
                }),
                ...(projectFilterState.rndExpense && {
                    maxRnDExpense: projectFilterState.rndExpense[1],
                }),
                ...(projectFilterState.totalHourlyrate && {
                    minTotalHourlyrate: projectFilterState.totalHourlyrate[0],
                }),
                ...(projectFilterState.totalHourlyrate && {
                    maxTotalHourlyrate: projectFilterState.totalHourlyrate[1],
                }),
            };
        }
    }, [projectFilterState]);

    const clearFilters = () => {
        setSearchTerm('');
        setNames([]);
        setDateError([]);
        setProjectRoles([]);
        setResponseDateError([]);
        setProjectFilterState({
            projectId: [],
            names: [],
            projectRoles: [],
            totalExpense: [0, null],
            rndExpense: [0, null],
            totalHourlyrate: [0, null],
        });
        setPositiveNumberError('');
        setPositiveNumberError2('');
        setPositiveNumberError3('');
        setProjectsCountError('');
        setProjectsCountError2('');
        setProjectsCountError3('');
        fetchTeamData();
        onApplyFilters({});
        triggerProjectClearFilters();
        setIsProjectFilterApplied(false);
        setShowDateError(false);
    };

    const applyFilters = () => {
        const filters = {
            ...(names?.length > 0 && { names }),
            ...(projectRoles?.length > 0 && { projectRoles }),
            ...(projectFilterState.totalExpense && {
                minTotalExpense: projectFilterState.totalExpense[0],
                maxTotalExpense: projectFilterState.totalExpense[1],
            }),
            ...(projectFilterState.rndExpense && {
                minRnDExpense: projectFilterState.rndExpense[0],
                maxRnDExpense: projectFilterState.rndExpense[1],
            }),
            ...(projectFilterState.totalHourlyrate && {
                minTotalHourlyrate: projectFilterState.totalHourlyrate[0],
                maxTotalHourlyrate: projectFilterState.totalHourlyrate[1],
            }),
        };
        fetchTeamData(filters);
    };

    return (
        <Drawer
            anchor="left"
            open={open}
            onClose={handleClose}
            variant="persistent"
            sx={styles.drawerPaper}
        >
            <Box sx={styles.drawerContainer}>
                <Box sx={styles.header}>
                    <Typography sx={styles.title}>
                        Team Member Filter
                    </Typography>
                </Box>
                <Box sx={styles.accordion}>
                    <Accordion
                        sx={{
                            height: "100%",
                            overflow: 'auto',
                            backgroundColor: isAccordionOpen ? '#FFFFFF' : 'transparent',
                            '&:hover': { backgroundColor: '#FFFFFF' },
                            boxShadow: 'none',
                            borderRadius: "20px",
                        }}
                        expanded={isAccordionOpen}
                    >
                        <AccordionDetails sx={styles.accordionDetails}>
                            <Box>
                                {filterFields
                                    .filter(field => field.label.toLowerCase().includes(searchTerm))
                                    .map((field, index) => (
                                        <Box key={index}>
                                            <FormControlLabel
                                                control={
                                                    <>
                                                        <Checkbox
                                                            checked={
                                                                field.label === "Member Name"
                                                                    ? showNames
                                                                    : field.label === "Project Role"
                                                                        ? showProjectRoles
                                                                        : field.label === "Total Expense"
                                                                            ? showTotalExpense
                                                                            : field.label === "QRE Expense"
                                                                                ? showRnDExpense
                                                                                : field.label === "Hourly Rate"
                                                                                    ? showTotalHourlyrate
                                                                                    : false
                                                            }
                                                            onChange={(e) => {
                                                                if (field.label === "Member Name") {
                                                                    setNames([]);
                                                                    if (e.target.checked) {
                                                                        setShowNames(true);
                                                                    } else {
                                                                        setShowNames(false);
                                                                    }
                                                                }
                                                                else if (field.label === "Project Role") {
                                                                    setProjectRoles([]);
                                                                    if (e.target.checked) {
                                                                        setShowProjectRoles(true);
                                                                    } else {
                                                                        setShowProjectRoles(false);
                                                                    }
                                                                }
                                                                else if (field.label === "Total Expense") {
                                                                    if (e.target.checked) {
                                                                        setShowTotalExpense(true);
                                                                    } else {
                                                                        setShowTotalExpense(false);
                                                                        setProjectFilterState(prev => ({
                                                                            ...prev,
                                                                            totalExpense: [0, null],
                                                                        }));
                                                                    }
                                                                } else if (field.label === "QRE Expense") {
                                                                    if (e.target.checked) {
                                                                        setShowRnDExpense(true);
                                                                    } else {
                                                                        setShowRnDExpense(false);
                                                                        setProjectFilterState(prev => ({
                                                                            ...prev,
                                                                            rndExpense: [0, null],
                                                                        }));
                                                                    }
                                                                } else if (field.label === "Hourly Rate") {
                                                                    if (e.target.checked) {
                                                                        setShowTotalHourlyRate(true);
                                                                    } else {
                                                                        setShowTotalHourlyRate(false);
                                                                        setProjectFilterState(prev => ({
                                                                            ...prev,
                                                                            totalHourlyrate: [0, null],
                                                                        }));
                                                                    }
                                                                }
                                                            }}
                                                            sx={{
                                                                "&.Mui-checked": {
                                                                    color: "#00A398",
                                                                },
                                                                "& .MuiSvgIcon-root": {
                                                                    fontSize: 20,
                                                                },
                                                            }}
                                                        />
                                                    </>
                                                }
                                                label={field.label}
                                            />
                                            {field.label === 'Member Name' && (
                                                <Collapse in={showNames}>
                                                    <TeamMemberSelector
                                                        teamName={names}
                                                        teamNameList={namesList}
                                                        setTeamName={setNames}
                                                    />
                                                </Collapse>
                                            )}
                                            {field.label === 'Project Role' && (
                                                <Collapse in={showProjectRoles}>
                                                    <ProjectRolesSelector
                                                        projectRoles={projectRoles}
                                                        projectRolesList={projectRolesList}
                                                        setProjectRoles={setProjectRoles}
                                                    />
                                                </Collapse>
                                            )}
                                            {field.label === 'Total Expense' && (
                                                <Collapse in={showTotalExpense}>
                                                    <Box display="flex" gap={3}>
                                                        <TextField
                                                            name="min"
                                                            type="number"
                                                            value={Array.isArray(projectFilterState?.totalExpense) ? projectFilterState.totalExpense[0] : ''}
                                                            onChange={handleFilterChange({ field: "totalExpense", scale: "min" })}
                                                            placeholder="Min Value"
                                                            fullWidth
                                                            InputProps={{
                                                                sx: styles.textField,
                                                            }}
                                                            InputLabelProps={{
                                                                style: { width: '100%', marginTop: "-10px" },
                                                            }}
                                                            // error={!!positiveNumberError}
                                                            // helperText={positiveNumberError || ""}
                                                            // FormHelperTextProps={{
                                                            //     sx: { textAlign: 'left', padding: 0, margin: 0, fontSize: '0.8rem', color: 'red' },
                                                            // }} error={!!positiveNumberError || !!totalExpenseError}
                                                            error={!!positiveNumberError}
                                                            helperText={positiveNumberError || ""}
                                                            FormHelperTextProps={{
                                                                sx: {
                                                                    textAlign: 'left', padding: 0, margin: 0, fontSize: '0.8rem', color: 'red'
                                                                }
                                                            }}
                                                            sx={{ padding: '0px' }}
                                                        />
                                                        <TextField
                                                            name="max"
                                                            type="number"
                                                            value={Array.isArray(projectFilterState?.totalExpense) ? projectFilterState.totalExpense[1] : ''}
                                                            onChange={handleFilterChange({ field: "totalExpense", scale: "max" })}
                                                            fullWidth
                                                            placeholder="Max Value"
                                                            sx={{ marginRight: "10px" }}
                                                            InputProps={{
                                                                sx: styles.textField,
                                                            }}
                                                        />
                                                    </Box>

                                                    {projectsCountError && (
                                                        <Typography color="error" variant="body2">
                                                            {projectsCountError}
                                                        </Typography>
                                                    )}
                                                    {/* </Box> */}
                                                </Collapse>
                                            )}
                                            {field.label === 'QRE Expense' && (
                                                <Collapse in={showRnDExpense}>
                                                    <Box display="flex" gap={3}>
                                                        <TextField
                                                            name="min"
                                                            type="number"
                                                            value={Array.isArray(projectFilterState?.rndExpense) ? projectFilterState.rndExpense[0] : ''}
                                                            onChange={handleFilterChange({ field: "rndExpense", scale: "min" })}
                                                            placeholder="Min Value"
                                                            fullWidth
                                                            InputProps={{
                                                                sx: styles.textField,
                                                            }}
                                                            InputLabelProps={{
                                                                style: { width: '100%', marginTop: "-10px" },
                                                            }}
                                                            error={!!positiveNumberError2}
                                                            helperText={positiveNumberError2 || ""}
                                                            FormHelperTextProps={{
                                                                sx: {
                                                                    textAlign: 'left', padding: 0, margin: 0, fontSize: '0.8rem', color: 'red'
                                                                }
                                                            }}
                                                            sx={{ padding: '0px' }}
                                                        />
                                                        <TextField
                                                            name="max"
                                                            type="number"
                                                            value={Array.isArray(projectFilterState?.rndExpense) ? projectFilterState.rndExpense[1] : ''}
                                                            onChange={handleFilterChange({ field: "rndExpense", scale: "max" })}
                                                            fullWidth
                                                            placeholder="Max Value"
                                                            sx={{ marginRight: "10px" }}
                                                            InputProps={{
                                                                sx: styles.textField,
                                                            }}
                                                        />
                                                    </Box>
                                                    {projectsCountError2 && (
                                                        <Typography color="error" variant="body2">
                                                            {projectsCountError2}
                                                        </Typography>
                                                    )}
                                                </Collapse>
                                            )}
                                            {field.label === 'Hourly Rate' && (
                                                <Collapse in={showTotalHourlyrate}>
                                                    <Box display="flex" gap={3}>
                                                        <TextField
                                                            name="min"
                                                            type="number"
                                                            value={Array.isArray(projectFilterState?.totalHourlyrate) ? projectFilterState.totalHourlyrate[0] : ''}
                                                            onChange={handleFilterChange({ field: "totalHourlyrate", scale: "min" })}
                                                            placeholder="Min Value"
                                                            fullWidth
                                                            InputProps={{
                                                                sx: styles.textField,
                                                            }}
                                                            error={!!positiveNumberError3}
                                                            helperText={positiveNumberError3 || ""}
                                                            FormHelperTextProps={{
                                                                sx: {
                                                                    textAlign: 'left', padding: 0, margin: 0, fontSize: '0.8rem', color: 'red'
                                                                }
                                                            }}
                                                            sx={{ padding: '0px' }}
                                                        />
                                                        <TextField
                                                            name="max"
                                                            type="number"
                                                            value={Array.isArray(projectFilterState?.totalHourlyrate) ? projectFilterState.totalHourlyrate[1] : ''}
                                                            onChange={handleFilterChange({ field: "totalHourlyrate", scale: "max" })}
                                                            fullWidth
                                                            placeholder="Max Value"
                                                            sx={{ marginRight: "10px" }}
                                                            InputProps={{
                                                                sx: styles.textField,
                                                            }}
                                                        />
                                                    </Box>
                                                    {projectsCountError3 && (
                                                        <Typography color="error" variant="body2">
                                                            {projectsCountError3}
                                                        </Typography>
                                                    )}
                                                </Collapse>
                                            )}
                                        </Box>
                                    ))}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                <Box sx={styles.footer}>
                    <ActionButton
                        label="Clear"
                        color={styles.clearButton.color}
                        onClick={clearFilters}
                    />
                    <ActionButton
                        label="Apply"
                        color={styles.applyButton.color}
                        onClick={applyFilters}
                    />
                </Box>
            </Box>
        </Drawer>
    );
}

export default Teamfilters;

