import { TableBody, TableCell, TableRow, Tooltip, Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import React, { useState, useEffect } from 'react'
import { Switch } from '@mui/material';
import { Link } from "react-router-dom";
import CompanyTableCell from "../Common/CompanyTableCell";
import { Authorization_header } from "../../utils/helper/Constant";
import axios from "axios";
import { BaseURL } from "../../constants/Baseurl";

const cellStyle = {
  whiteSpace: "nowrap",
  borderRight: "1px solid #ddd",
  borderLeft: "1px solid #ddd",  // Added
  textAlign: "left",
  fontSize: "13px",
  py: 0,
};

const cellStyle1 = {
  whiteSpace: "nowrap",
  borderRight: "1px solid #ddd",
  borderLeft: "1px solid #ddd",  // Added
  textAlign: "center",
  fontSize: "13px",
  py: 0,
};

const currencyCellStyle = {
  whiteSpace: "nowrap",
  borderRight: "1px solid #ddd",
  borderLeft: "1px solid #ddd",  // Added
  textAlign: "right",
  fontSize: "13px",
  py: 0,
  color: "#FD5707",
};

const cellLinkStyle = {
  ...cellStyle,
  textAlign: "right",
  color: "#00A398",
  borderLeft: "1px solid #ddd",  // Added
};

function CompanyTableBody({ filledRows }) {

  function toCamelCaseWithFirstLetterCapital(str) {
    const camelCaseStr = str
      .toLowerCase()
      .replace(/[-_ ]+(\w)/g, (_, c) => c.toUpperCase());
    return camelCaseStr.charAt(0).toUpperCase() + camelCaseStr.slice(1);
  }

  const [toggleStates, setToggleStates] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [companyId, setCurrentCompanyId] = useState(null);
  const [currentToggleState, setCurrentToggleState] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [companyProjects, setCompanyProjects] = useState(null);

  function formatCurrency(amount, locale, currency) {
    // Create a new Intl.NumberFormat instance
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    let formattedAmount = formatter.format(amount);
    formattedAmount = formattedAmount.replace(/[a-zA-Z]/g, '').trim();
    return formattedAmount;
  }

  useEffect(() => {
    const initialToggleStates = {};
    filledRows.forEach(row => {
      initialToggleStates[row?.companyId] = row?.autoSendInteractions === 1;

    });
    setToggleStates(initialToggleStates);
  }, [filledRows]);

  const handleToggleChange = (companyId) => async (event) => {
    const newState = event.target.checked;
    setCurrentCompanyId(companyId);
    setCurrentToggleState(newState);
    setDialogOpen(true);
    // setToggleStates((prevStates) => ({
    //   ...prevStates,
    //   [companyId]: newState,
    // }));
  };

  const handleDialogClose = async (confirm) => {
    setDialogOpen(false);
    if (confirm) {
      const querryData = {
        toggle: currentToggleState ? "1" : "0",
      };

      try {
        const res = await axios.post(
          `${BaseURL}/api/v1/company/${companyId}/${currentToggleState ? "1" : "0"}/toggle-auto-interactions`,
          querryData,
          Authorization_header()
        );
        setSnackbarMessage(`Automatic interactions turned ${currentToggleState ? "on" : "off"} successfully.`);
        setToggleStates((prevStates) => ({
          ...prevStates,
          [companyId]: currentToggleState,
        }));

      } catch (error) {
        console.error("Error sending interactions:", error);
        setSnackbarMessage("Error sending interactions. Please try again.");
      }
      finally {
        setSnackbarOpen(true);
      }
    }
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  const fetchCompanyProjects = async (sortParams = {}, companyId) => {

    const params = {};

    if (sortParams && sortParams.sortField && sortParams.sortOrder) {
      params.sortField = sortParams.sortField;
      params.sortOrder = sortParams.sortOrder;
    }

    const payLoad = {
      params: params,
      headers: Authorization_header()?.headers,
    };

    try {
      const response3 = await axios.get(
        `${BaseURL}/api/v1/projects/${localStorage.getItem(
          "userid"
        )}/a0ds/get-projects?companyIds=[${JSON.stringify(companyId)}]`, payLoad
      );
      setCompanyProjects(response3?.data?.data?.list);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <TableBody>
        {filledRows?.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {/* <TableCell sx={cellStyle}>{row?.companyIdentifier || ""}</TableCell> */}
            {/* <CompanyTableCell id={row?.companyId} name={row?.companyIdentifier} sCase={row} /> */}
            <CompanyTableCell id={row?.companyId} name={row?.companyName} sCase={row} />
            <TableCell sx={cellStyle}>{row?.companyIdentifier || ""}</TableCell>
            <TableCell sx={{ ...cellLinkStyle, textAlign: "center" }}>
              <Link to={`/projects?companyId=${row.companyId}`}>
                <span
                  style={{ color: "#00A398", textDecoration: "underline", cursor: "pointer" }}
                >
                  {row?.projectsCount || ""}
                </span>
              </Link>
            </TableCell>
            <TableCell sx={cellStyle}>{row?.billingCountry || ""}</TableCell>
            <TableCell sx={cellStyle1}>
              {row?.companyId && (
                <Tooltip>
                  <Switch
                    checked={toggleStates[row?.companyId] || false}
                    onChange={handleToggleChange(row?.companyId)}
                    color="warning"
                  />
                </Tooltip>
              )}
            </TableCell>
            {/* <TableCell sx={currencyCellStyle}>
              {row?.totalProjectCost && (String.fromCharCode(parseInt(row?.currencySymbol, 16)))}
              {row?.totalProjectCost 
                ? parseFloat(row?.totalProjectCost).toFixed(2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                : ""}
            </TableCell> */}
            <TableCell sx={currencyCellStyle}>
              {row?.totalProjectCost ? formatCurrency(row?.totalProjectCost, "en-US", row?.currency || "USD") : ""}
            </TableCell>
            {/* <TableCell sx={currencyCellStyle}>
              {row?.totalRnDCost && (String.fromCharCode(parseInt(row?.currencySymbol, 16)))}
              {row?.totalRnDCost
                ? parseFloat(row?.totalRnDCost).toFixed(2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                : ""}
            </TableCell> */}
            <TableCell sx={currencyCellStyle}>
              {row?.totalRnDCost ? formatCurrency(row?.totalRnDCost, "en-US", row?.currency || "USD") : ""}
            </TableCell>
            <TableCell sx={cellStyle}>
              <Link to="/employees/info">{row?.primaryContact || ""}</Link>
            </TableCell>
            <TableCell sx={cellStyle}>{row?.phone || ""}</TableCell>
            <TableCell sx={cellStyle}>
              {row?.email || ""}
            </TableCell>
            {/* <TableCell sx={{ ...cellStyle, color: "#FD5707", textAlign: "left" }}>
              {row?.surveyStatus ? toCamelCaseWithFirstLetterCapital(row.surveyStatus) : ""}
            </TableCell>
            <TableCell sx={{ ...cellStyle, color: "#00A398", textAlign: "left" }}>
              {row?.qrePercentage !== null & row?.qrePercentage !== undefined
                ? parseFloat(row?.qrePercentage).toFixed(2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                : ""}
            </TableCell>
            <TableCell sx={{ ...cellStyle, color: "#00A398", textAlign: "left" }}>
              {row?.fteCost ? formatCurrency(row?.fteCost, "en-US", row?.currency || "USD") : ""}
            </TableCell>
            <TableCell sx={{ ...cellStyle, color: "#00A398", textAlign: "left" }}>
              {row?.subconCost ? formatCurrency(row?.subconCost, "en-US", row?.currency || "USD") : ""}
            </TableCell>
            <TableCell sx={{ ...cellStyle, color: "#00A398", textAlign: "left" }}>
              {row?.fteQRE ? formatCurrency(row?.fteQRE, "en-US", row?.currency || "USD") : ""}
            </TableCell>
            <TableCell sx={{ ...cellStyle, color: "#00A398", textAlign: "left" }}>
              {row?.subconQRE ? formatCurrency(row?.subconQRE, "en-US", row?.currency || "USD") : ""}
            </TableCell>
            <TableCell sx={currencyCellStyle}>
              {row?.totalQRE ? formatCurrency(row?.totalQRE, "en-US", row?.currency || "USD") : ""}
            </TableCell>
            <TableCell sx={currencyCellStyle}>
              {row?.rndCredits ? formatCurrency(row?.totalQRE, "en-US", row?.currency || "USD") : ""}
            </TableCell>
            <TableCell sx={currencyCellStyle}>
              {row?.projectStatus}
            </TableCell>
            <TableCell sx={currencyCellStyle}>
              {row?.dataGathering}
            </TableCell>
            <TableCell sx={currencyCellStyle}>
              {row?.pendingData}
            </TableCell>
            <TableCell sx={currencyCellStyle}>
              {row?.timesheetStatus}
            </TableCell>
            <TableCell sx={currencyCellStyle}>
              {row?.FTEExpenseStatus}
            </TableCell>
            <TableCell sx={currencyCellStyle}>
              {row?.subconExpenseStatus}
            </TableCell>
            <TableCell sx={currencyCellStyle}>
              {row?.lastUpdatedBy}
            </TableCell>
            <TableCell sx={currencyCellStyle}>
              {row?.lastUpdatedDate}
            </TableCell>
            <TableCell sx={currencyCellStyle}>
              {row?.notes}
            </TableCell> */}
          </TableRow>
        ))}
      </TableBody>
      <Dialog
        open={dialogOpen}
        onClose={() => handleDialogClose(false)}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
        <DialogTitle id="confirmation-dialog-title"><h6>Confirm Action</h6></DialogTitle>
        <DialogContent>
          Do you want to turn {currentToggleState ? "on" : "off"} auto send interactions for all projects?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDialogClose(false)}
            variant="contained"
            sx={{ color: "white", backgroundColor: "#9F9F9F", "&:hover": { backgroundColor: "#9F9F9F" }, borderRadius: "20px", mr: 2 }}
          >
            No
          </Button>
          <Button
            onClick={() => handleDialogClose(true)}
            variant="contained"
            sx={{ color: "white", backgroundColor: "#00A398", "&:hover": { backgroundColor: "#00A398" }, borderRadius: "20px" }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default CompanyTableBody