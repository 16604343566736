import React from 'react'

function PageNotFound() {
  return (
    <div style={{display:"flex", height:"100vh", justifyContent:"center",alignItems: 'center', backgroundColor:"#8c82b4"}}>
        <img src="https://res.cloudinary.com/db84fd6qo/image/upload/v1705495137/404_myey1j.png" alt="Page Not Found"/>
    </div>
  )
}

export default PageNotFound