import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, InputLabel, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { formattedDateOnly } from "../../../utils/helper/FormatDatetime";
import EditableInput from "../../Common/EditableInput";

const styles = {
  flexBox: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #E4E4E4",
    px: 2,
  },
  flexBoxItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 2,
    mt: 2,
  },
  textStyle: {
    fontWeight: 600,
    mt: 1,
    display: "flex",
    alignItems: "center",
  },
  expandMoreIcon: {
    borderRadius: "50%",
    fontSize: "15px",
    backgroundColor: "#404040",
    color: "white",
    mr: 1,
    transition: "transform 0.3s ease",
  },
  inputBase: {
    borderRadius: "20px",
    height: "32px",
    border: "1px solid #E4E4E4",
    width: "200px",
    paddingLeft: "9px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    fontSize: "1rem",
    color: "#00A398",
  },
  label: {
    color: "#404040",
    fontSize: "14px",
  },
};

function ProjectTimeline({ data, editMode, editedValues, handleEditChange }) {
  const [visibility, setVisibility] = useState(false);

  const toggleVisibility = () => {
    setVisibility((prevVisibility) => !prevVisibility);
  };


  return (
    <Box sx={styles.flexBox}>
      <Typography sx={styles.textStyle} onClick={toggleVisibility}>
        <ExpandMoreIcon
          sx={{
            ...styles.expandMoreIcon,
            transform: visibility ? "rotate(180deg)" : "rotate(0deg)",
          }}
        />
        Project Timeline
      </Typography>
      {visibility && (
        <>
          <Box sx={styles.flexBoxItem}>
            <div>
              <InputLabel sx={styles.label}>Kickoff Date</InputLabel>
              <span style={styles.inputBase}>
                {formattedDateOnly(editedValues.startDate)}
              </span>
            </div>
            <div>
              <InputLabel sx={styles.label}>Deadline Date</InputLabel>
              <span style={styles.inputBase}>
                {formattedDateOnly(editedValues.endDate)}
              </span>
            </div>
            {/* <EditableInput
              label="Kickoff Date"
              value={editedValues.startDate}
              onChange={(e) => handleEditChange("startDate", e.target.value)}
              // disabled
              type="date"
            />
            <EditableInput
              label="Deadline Date"
              value={editedValues.endDate}
              onChange={(e) => handleEditChange("endDate", e.target.value)}
              // disabled
              type="date"
            /> */}
            <EditableInput
              label="Planned Duration"
              value={editedValues.plannedDuration}
              onChange={(e) =>
                handleEditChange("plannedDuration", e.target.value)
              }
              disabled={!editMode}
              type="number"
            />
          </Box>
          <Box sx={styles.flexBoxItem}>
            <EditableInput
              label="Actual Start Date"
              value={editedValues.actualStartDate}
              onChange={(e) =>
                handleEditChange("actualStartDate", e.target.value)
              }
              disabled={!editMode}
              type="date"
            />
            <EditableInput
              label="Actual End Date"
              value={editedValues.actualEndDate}
              onChange={(e) =>
                handleEditChange("actualEndDate", e.target.value)
              }
              disabled={!editMode}
              type="date"
            />
            <EditableInput
              label="Actual Duration"
              value={editedValues.actualDuration}
              onChange={(e) =>
                handleEditChange("actualDuration", e.target.value)
              }
              disabled={!editMode}
              type="number"
            />
          </Box>
        </>
      )}
    </Box>
  );
}

export default ProjectTimeline;
