import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { BaseURL } from "../../constants/Baseurl";
import { FilterListContext } from "../../context/FiltersListContext";
import AdditionalDetails from "./Details/AdditionalDetails";
import BasicDetails from "./Details/BasicDetails";
import Milestones from "./Details/Milestones";
import ProjectTimeline from "./Details/ProjectTimeline";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";
import { Authorization_header, token_obj } from "../../utils/helper/Constant";

const styles = {
  mainBox: {
    display: "flex",
    justifyContent: "space-between",
    px: 2,
    py: 1,
    borderTop: "1px solid #E4E4E4",
  },
  textStyle: { fontWeight: 600, px: 2, mt: 1 },
  flexBox: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #E4E4E4",
  },
  flexBoxItem: {
    display: "flex",
    justifyContent: "space-between",
    mt: 1,
    gap: 2,
    px: 2,
  },
  label: {
    color: "#404040",
    fontSize: "14px",
  },
  inputBase: {
    borderRadius: "20px",
    height: "40px",
    border: "1px solid #E4E4E4",
    pl: 1,
    mb: 0.5,
  },
  expandMoreIcon: {
    borderRadius: "50%",
    fontSize: "15px",
    backgroundColor: "#404040",
    color: "white",
    mr: 1,
    transition: "transform 0.3s ease",
  },
  updateInfo: {
    color: "#9F9F9F",
    fontSize: "12px",
  },
  editButton: {
    borderRadius: "20px",
    backgroundColor: "#00A398",
    color: "white",
    textTransform: "capitalize",
    height: "30px",
    "&:hover": { backgroundColor: "#00A398" },
  },
  editIcon: {
    fontSize: "20px",
    mr: 1,
  },
};

function Details({
  data,
  latestUpdateTime,
  modifiedBy,
  fetchData,
  milestones,
}) {
  const [editMode, setEditMode] = useState(false);
  const { clientList } = useContext(FilterListContext);
  const [errors, setErrors] = useState({});

  const [editedValues, setEditedValues] = useState({
    companyId: null,
    projectName: null,
    projectCode: null,
    projectManagerId: null,
    projectType: null,
    projectPortfolio: null,
    notes: null,
    accountingYear: null,
    startDate: null,
    endDate: null,
    plannedDuration: null,
    actualStartDate: null,
    actualEndDate: null,
    actualDuration: null,
    projectsIndustry: null,
    natureofProject: null,
    EmpBlendedRatePerHour: null,
    successCriteria: null,
    techStack: null,
    projectStatus: null,
    description: null,
  });

  useEffect(() => {
    setEditedValues({
      companyId: data?.companyId || null,
      projectName: data?.projectName || null,
      projectCode: data?.projectCode || null,
      projectManagerId: data?.projectManagerId || null,
      projectType: data?.projectType || null,
      projectPortfolio: data?.projectPortfolio || null,
      notes: data?.notes || null,
      accountingYear: data?.accountingYear || null,
      startDate: data?.startDate || null,
      endDate: data?.endDate || null,
      plannedDuration: data?.plannedDuration || null,
      actualStartDate: data?.actualStartDate || null,
      actualEndDate: data?.actualEndDate || null,
      actualDuration: data?.actualDuration || null,
      projectsIndustry: data?.projectsIndustry || null,
      natureofProject: data?.natureofProject || null,
      EmpBlendedRatePerHour: data?.EmpBlendedRatePerHour || null,
      successCriteria: data?.successCriteria || null,
      techStack: data?.techStack || null,
      projectStatus: data?.projectStatus || null,
      description: data?.description || null,
    });
  }, [data]);

  const handleEditChange = (field, value) => {
    setEditedValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleEditClick = async () => {
    let newErrors = {};

    // Validate required fields
    if (!editedValues.projectName.trim())
      newErrors.projectName = "Project Name is required";
    if (!editedValues.companyId.trim())
      newErrors.companyId = "Account is required";

    // If there are errors, set them and return
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // If editMode is true, perform the edit operation
    if (editMode) {
      const tokens = localStorage.getItem('tokens');
      const token_obj = JSON.parse(tokens);
      toast.promise(
        (async () => {
          const url = `${BaseURL}/api/v1/projects/${localStorage.getItem("userid")}/${data?.companyId}/${data?.projectId}/edit-project`;
          const tokens = localStorage.getItem('tokens');
          const token_obj = JSON.parse(tokens);

          try {
            const response = await axios.get(url, {
              headers: {
                'Authorization': `Bearer ${token_obj?.accessToken}`,
                // Add any other necessary headers here
              },
              params: editedValues, // Assuming editedValues are query params or data to be sent with the request
            });

            if (response.data.success) {
              setErrors({});
              fetchData();
            }
            return response;
          } catch (error) {
            console.error("Error updating data:", error);
            throw error.response
              ? error.response.data
              : new Error("Network or server error");
          } finally {
            setErrors({});
            setEditMode(false);
          }
        })(),
        {
          // loading: "Editing project details...",
          success: (response) =>
            response.data.message || "Project details edited successfully",
          error: (response) =>
            response.data.error?.message || "Failed to edit project details.",
        }
      );
    } else {
      setEditMode(true);
    }
  };
  const handleSaveMilestones = async (newMilestones) => {
    for (const milestone of newMilestones) {
      try {
        const response = await axios.post(
          `${BaseURL}/api/v1/projects/${localStorage.getItem("userid")}/${data?.overview?.[0]?.companyId
          }/${data?.overview?.[0]?.projectId}/add-new-milestone`,
          milestone,
          {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${token_obj.accessToken}`

            }
          }
        );
        fetchData();
      } catch (error) {
        console.error("Error adding milestone:", error);
      }
    }
  };
  return (
    <>
      <Box sx={styles.mainBox}>
        <Box>
          <Typography sx={styles.updateInfo}>
            Updated {latestUpdateTime}
          </Typography>
          <Typography sx={styles.updateInfo}>
            Updated by: {modifiedBy}
          </Typography>
        </Box>
        {useHasAccessToFeature("F015", "P000000001") && (
          <>
            {editMode ? (
              <div style={{ display: "flex", gap: "20px" }}>
                <Button
                  variant="contained"
                  sx={styles.editButton}
                  onClick={() => setEditMode(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={styles.editButton}
                  onClick={() => handleEditClick()}
                >
                  Save Changes
                </Button>
              </div>
            ) : (
              <Button
                variant="contained"
                startIcon={<EditIcon sx={styles.editIcon} />}
                sx={styles.editButton}
                onClick={() => setEditMode(true)}
              >
                Edit
              </Button>
            )}
          </>
        )}
      </Box>
      <BasicDetails
        data={data}
        editMode={editMode}
        editedValues={editedValues}
        handleEditChange={handleEditChange}
        clientData={clientList}
        errors={errors}
      />
      <ProjectTimeline
        data={data}
        editMode={editMode}
        editedValues={editedValues}
        handleEditChange={handleEditChange}
      />

      {/* Additional Details */}
      {/* <AdditionalDetails
        data={data}
        editMode={editMode}
        editedValues={editedValues}
        handleEditChange={handleEditChange}
      /> */}
      {/* <Milestones
        milestones={milestones}
        onSaveMilestones={handleSaveMilestones}
      /> */}
      <Toaster />
    </>
  );
}

export default Details;
