import { CircularProgress, Table, TableContainer, Box, Drawer, Badge, Button } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { BaseURL } from "../../constants/Baseurl";
import { FilterListContext } from "../../context/FiltersListContext";
import { ProjectContext } from "../../context/ProjectContext";
import { areFiltersApplied } from "../../utils/helper/AreFiltersApplied";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";
import CustomPagination from "../Common/CustomPagination";
import TableHeader from "../Common/TableHeader";
import TableIntro from "../Common/TableIntro";
import usePinnedData from "../CustomHooks/usePinnedData";
import ProjectModal from "./ProjectModal";
import ProjectsTableBody from "./ProjectsTableBody";
import { Authorization_header } from "../../utils/helper/Constant";
import SpocModalForm from "../Common/SpocModalForm";
import ProjectsAddModal from "./ProjectsAddModal";
import SheetsListing from "../Common/SheetsListing";
import { SheetsContext } from "../../context/SheetsContext";
import { HiFilter } from "react-icons/hi";
import ProjectsFilters from "./ProjectsFilters";
import DownloadModalForm from "../Common/DownloadModalForm";
import { ClientContext } from "../../context/ClientContext";

const tableData = {
  columns: [
    "Project ID",
    "Project Name",
    "Account",
    "Fiscal Year",
    "SPOC Name",
    "SPOC Email",
    "Project Status",
    "Project Cost - FTE",
    "Project Cost - Subcon",
    "Project Cost - Total",
    "Project Hours - FTE",
    "Project Hours - Subcon",
    "Project Hours - Total",
    "QRE (%) - Potential",
    "QRE (%) - Adjustment",
    "QRE (%) - Final",
    "QRE - FTE",
    "QRE - Subcon",
    "QRE - Total",
    "R&D Credits",
    "Data Gathering",
    "Pending Data",
    "Timesheet Status",
    "Cost Status - Employee",
    "Cost Status - Subcon",
    "Survey - Status",
    "Survey - Sent Date",
    "Survey - Reminder Sent Date",
    "Survey - Response Date",
    "Interaction - Status",
    "Technical Interview Status",
    "Technical Summary Status",
    "Financial summary Status",
    "Claims Form Status",
    "Final Review Status",
    "Notes",
    "Last Updated Date",
    "Last Updated By",
    "Project Identifer",
  ],
};

const styles = {
  uploadButtonStyle: {
    borderRadius: "20px",
    textTransform: "capitalize",
    backgroundColor: "#00A398",
    "&:hover": { backgroundColor: "#00A398" },
  },
  buttonStyle: {
    mr: 1,
    borderRadius: "20px",
    textTransform: "capitalize",
    backgroundColor: "#9F9F9F",
    "&:hover": { backgroundColor: "#9F9F9F" },
  }, iconStyle: { fontSize: "20px", color: "#9F9F9F" },
  newCompanyButtonStyle: {
    textTransform: "capitalize",
    borderRadius: "20px",
    backgroundColor: "#00A398",
    mr: 2,
    mb: "-5%",
    "&:hover": {
      backgroundColor: "#00A398",
    },
  },
}

const styleConstants = {
  filterDownloadStyle: {
    color: "white",
    borderRadius: "50%",
    backgroundColor: "#00A398",
    fontSize: "28px",
    padding: "5px",
    marginRight: "16px",
    cursor: "pointer",
  },
  tableContainerStyle: {
    borderLeft: "1px solid #E4E4E4",
  },
  overlay: {
  },
  containerDimmed: {
  },
};

let editFields = [];
function ProjectsTableStack({ onApplyFilters,
  page,
  documentType = "",
  data,
  latestUpdateTime,
  getSelectedTab,
  projectsSheets }) {
  const {
    projects,
    projectId,
    company,
    fetchProjects,
    projectFilterState,
    setCurrentState,
    currentState,
    loading,
    setProjectFilterState,
  } = useContext(ProjectContext);
  const { clientData } = useContext(ClientContext);
  const [currentPageProjects, setCurrentPageProjects] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [modalOpen, setModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const { pinnedObject } = usePinnedData();
  const { fetchUserDetails } = useContext(FilterListContext);
  const [updateIds, setUpdateIds] = useState([]);
  const [updatePurpose, setUpdatePurpose] = useState("updates");
  const [handleConfirmationModalOpen, setHandleConfirmationModalOpen] = useState(false);
  const [updateData, setUpdateData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [showSendUpdates, setShowSendupdates] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [spocProjects, setSpocProjects] = useState([]);
  const { fetchProjectsSheets } = useContext(SheetsContext);
  const [spockName, setSpockName] = useState("");
  const [spockEmail, setSpockEmail] = useState("");
  const [sheetsToBeShown, setSheetsToBeShown] = useState([]);
  const [filterClicked, setFilterClicked] = useState(false);
  // const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const [editFields, setEditFields] = useState([]);
  const [activeFilterCount, setActiveFilterCount] = useState(0);
  const projectNavs = [
    { name: "All Projects", isAuth: true },
    { name: "Uploaded Sheets", isAuth: true }
  ];
  const [selectedTab, setSelectedTab] = useState("All Projects");
  const [pinStates, setPinStates] = useState({
    "All Projects": false,
    "Recently Viewed": false,
  });
  console.log("clientData", clientData);


  const [showAddProjectsModal, setShowAddProjectsModal] = useState(false);

  const appliedFilters = {
    company: projectFilterState.company,
  };

  let projectsOptions;
  useEffect(() => {
    const shouldFetchWithFiltersProjects =
      projectFilterState.company?.length > 0 ||
      projectFilterState.spocName?.length > 0 ||
      projectFilterState.spocEmail?.length > 0 ||
      projectFilterState.accountingYear?.length > 0 ||
      projectFilterState.totalExpense?.length > 0 ||
      projectFilterState.rndExpense?.length > 0 ||
      projectFilterState.rndPotential?.length > 0;

    if (shouldFetchWithFiltersProjects) {
      projectsOptions = {
        ...(projectFilterState.companyId?.length > 0 && {
          companyIds: projectFilterState.companyId,
        }),
        ...(projectFilterState.spocName?.length > 0 && {
          spocName: projectFilterState.spocName,
        }),
        ...(projectFilterState.spocEmail?.length > 0 && {
          spocEmail: projectFilterState.spocEmail,
        }),
        ...(projectFilterState.accountingYear?.length > 0 && {
          accountingYear: projectFilterState.accountingYear,
        }),
        ...(projectFilterState.totalExpense && {
          minTotalExpense: projectFilterState.totalExpense[0],
        }),
        ...(projectFilterState.totalExpense && {
          maxTotalExpense: projectFilterState.totalExpense[1],
        }),
        ...(projectFilterState.rndExpense && {
          minRnDExpense: projectFilterState.rndExpense[0],
        }),
        ...(projectFilterState.rndExpense && {
          maxRnDExpense: projectFilterState.rndExpense[1],
        }),
        ...(projectFilterState.rndPotential && {
          minRnDPotential: projectFilterState.rndPotential[0],
        }),
        ...(projectFilterState.rndPotential && {
          maxRnDPotential: projectFilterState.rndPotential[1],
        }),
      };
    }
  }, [projectFilterState]);

  useEffect(() => {
    document.body.style.overflow = filterPanelOpen ? "hidden" : "";
    return () => {
      document.body.style.overflow = "";
    };
  }, [filterPanelOpen]);

  useEffect(() => {
    if (projectsSheets && projectsSheets.length > 0) {
      let i = 0;
      let newSheets = [];
      for (; i < itemsPerPage; i++) {
        let obj = {};
        for (let key in projectsSheets[0]) {
          obj[key] = "";
        }
        newSheets.push(obj);
      }
      setSheetsToBeShown(newSheets);
    }
  }, [itemsPerPage]);

  const handleUpdateSpockData = (spName, spMail) => {
    setSpockName(spName);
    setSpockEmail(spMail);
  }

  const handleSelectedTab = (name) => {
    setSelectedTab(name);
  }

  const handleSendMail = async ({ updateIds }) => {
    toast.loading(`${updatePurpose} sending...`);
    const querryData = { updateIds: [...updateIds], purpose: "PROJECT", spocName: spockName, spocEmail: spockEmail };
    if (updatePurpose === "updates") {
      querryData.sendupdate = true;
    }
    try {
      const res = await axios.post(`${BaseURL}/api/v1/contacts/${localStorage.getItem(
        "userid"
      )}/update-spoc`, querryData, Authorization_header());
      toast.dismiss();
      toast.success(`${updatePurpose} sent successfully...`);

    } catch (error) {
      toast.dismiss();
      toast.error(error?.response?.data?.message || `Failed to send ${updatePurpose}. Server error`);
      console.error(error);
    }
  }


  const filterSpocProjects = () => {

    const sp = projects?.filter((p) => {
      return ((!p.spocName || !p.spocEmail || p.spocEmail === "" || p.spocName === ""))
    })
    setSpocProjects(sp);
  }

  useEffect(() => {
    filterSpocProjects();
  }, [projects])

  useEffect(() => {
    const updatedPinStates = {
      "All Projects": pinnedObject.PROJ === "ALL",
      "Recently Viewed": pinnedObject.PROJ === "RV",
    };
    setPinStates(updatedPinStates);
  }, [pinnedObject.PROJ]);

  useEffect(() => {
    const shouldFetchWithFiltersProjects =
      projectFilterState.companyIds?.length > 0 ||
      projectFilterState.spocName?.length > 0 ||
      projectFilterState.spocEmail?.length > 0 ||
      projectFilterState.accountingYear?.length > 0 ||
      projectFilterState.totalExpense?.length > 0 ||
      projectFilterState.rndExpense?.length > 0 ||
      projectFilterState.rndPotential?.length > 0;

    if (shouldFetchWithFiltersProjects) {
      let options = {
        ...(projectFilterState.companyId?.length > 0 && {
          client: projectFilterState.companyId,
        }),
        ...(projectFilterState.accountingYear?.length > 0 && {
          accountingYear: projectFilterState.accountingYear,
        }),
        ...(projectFilterState.spocName?.length > 0 && {
          spocName: projectFilterState.spocName,
        }),
        ...(projectFilterState.spocEmail?.length > 0 && {
          spocEmail: projectFilterState.spocEmail,
        }),
        ...(projectFilterState.totalExpense && {
          minTotalExpense: projectFilterState.totalExpense[0],
        }),
        ...(projectFilterState.totalExpense && {
          maxTotalExpense: projectFilterState.totalExpense[1],
        }),
        ...(projectFilterState.rndExpense && {
          minRnDExpense: projectFilterState.rndExpense[0],
        }),
        ...(projectFilterState.rndExpense && {
          maxRnDExpense: projectFilterState.rndExpense[1],
        }),
        ...(projectFilterState.rndPotential && {
          minRnDPotential: projectFilterState.rndPotential[0],
        }),
        ...(projectFilterState.rndPotential && {
          maxRnDPotential: projectFilterState.rndPotential[1],
        }),
      };
      fetchProjects(options);
    }
    // else {
    //   // fetchProjects();
    // }
  }, [currentState]);

  const applyFiltersAndFetch = (filters) => {
    if (areFiltersApplied(appliedFilters)) {
      fetchProjects(filters);
    } else {
      // toast.error("Please select at least one filter.");
      fetchProjects(filters);
    }
  };

  const totalPagesProjects = Math.ceil(filteredRows?.length / itemsPerPage);
  const totalPagesSheets = Math.ceil(projectsSheets?.length / itemsPerPage);

  const handleChangePage = (newPage) => {
    setCurrentPageProjects(newPage);
  };

  const handleChangeItemsPerPage = (items) => {
    setItemsPerPage(items);
    setCurrentPageProjects(1);
  };

  const currentData = filteredRows?.slice(
    (currentPageProjects - 1) * itemsPerPage,
    currentPageProjects * itemsPerPage
  );

  while (currentData?.length < itemsPerPage) {
    currentData?.push({});
  }

  const handleUploadClick = () => {
    setModalOpen(true);
  };


  const handleProjectsUploadClick = () => {
    setShowAddProjectsModal(true);
  }

  const handleProjectsUploadClose = () => {
    setShowAddProjectsModal(false);
  }

  const handleSendUpdatesClick = (val) => {
    setUpdateIds([]);
    if (updatePurpose === 'updates') {
      setShowSendupdates(true);
    }
    setUpdatePurpose("updates");
  }

  const handleDownloadClick = (val) => {
    setDownloadData([]);
    setShowDownloadModal(true);
  }

  const handleShowSendUpdates = () => {
    setShowSendupdates(!showSendUpdates);
  }

  const handleCloseDownloadModal = () => {
    setShowDownloadModal(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleSearch = (input) => {
    setSearch(input);
  };

  useEffect(() => {
    if (projects) {
      const filteredData = projects?.filter(
        (task) => {
          return task?.projectName?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.projectId?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.projectCode?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task.firstName?.toString()?.toLowerCase().includes(search.toLowerCase()) ||
            task.lastName?.toString()?.toLowerCase().includes(search.toLowerCase()) ||
            task.middleName?.toString()?.toLowerCase().includes(search.toLowerCase()) ||
            task.companyName?.toString()?.toLowerCase().includes(search.toLowerCase()) ||
            task.spocEmail?.toString()?.toLowerCase().includes(search.toLowerCase()) ||
            task.spocName?.toString()?.toLowerCase().includes(search.toLowerCase()) ||
            task?.s_data_gathering?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.companyId?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.company?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.accountingYear?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.s_pending_data?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.s_project_status?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.surveyStatus?.toString()?.toLowerCase()?.includes(search?.toLocaleLowerCase()) ||
            task.s_fte_cost?.toString()?.toLowerCase().includes(search.toLowerCase()) ||
            task.s_subcon_cost?.toString()?.toLowerCase().includes(search.toLowerCase()) ||
            task.s_total_project_cost?.toString()?.toLowerCase().includes(search.toLowerCase()) ||
            task?.s_data_gathering?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.rndPotential?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.s_rnd_adjustment?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.rndFinal?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.s_fte_qre_cost?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.s_subcon_qre_cost?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.s_qre_cost?.toString()?.toLowerCase()?.includes(search?.toLocaleLowerCase()) ||
            task?.s_rd_credits?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.s_timesheet_status?.toString()?.toLowerCase()?.includes(search?.toLocaleLowerCase()) ||
            task.s_fte_cost_status?.toString()?.toLowerCase().includes(search.toLowerCase()) ||
            task.s_subcon_cost_status?.toString()?.toLowerCase().includes(search.toLowerCase()) ||
            task.surveySentDate?.toString()?.toLowerCase().includes(search.toLowerCase()) ||
            task?.reminderSentDate?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.surveyResponseDate?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.surveyResponse?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.s_technical_interview_status?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.s_technical_summary_status?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.s_financial_summary_status?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.s_claims_form_status?.toString()?.toLowerCase()?.includes(search?.toLocaleLowerCase()) ||
            task?.s_final_review_status?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.s_last_updated_timestamp?.toString()?.toLowerCase()?.includes(search?.toLocaleLowerCase()) ||
            task.s_last_updated_by?.toString()?.toLowerCase().includes(search.toLowerCase()) ||
            task.s_fte_hours?.toString()?.toLowerCase().includes(search.toLowerCase()) ||
            task.s_subcon_hours?.toString()?.toLowerCase().includes(search.toLowerCase()) ||
            task?.s_total_hours?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.s_pending_data?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.s_notes?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.s_interaction_status?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.projectIdentifier?.toString()?.includes(search?.toLowerCase());
        })

      setFilteredRows(filteredData);
      setCurrentPageProjects(1);

    } else {
      setFilteredRows([]);
    }
  }, [projects, search]);

  const handleSelectedHeaderItem = (item) => {
    setCurrentState(item);
  };

  useEffect(() => {
    setCurrentState(
      pinnedObject?.PROJ === "RV" ? "Recently Viewed" : "All Projects"
    );
  }, [localStorage?.getItem("keys")]);

  const togglePinState = (selectedHeading) => {
    setPinStates((prevStates) => {
      const resetStates = Object.keys(prevStates).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      const newState = {
        ...resetStates,
        [selectedHeading]: !prevStates[selectedHeading],
      };

      const allFalse =
        !newState["All Projects"] && !newState["Recently Viewed"];
      if (allFalse) {
        newState["All Projects"] = true;
      }

      return newState;
    });
  };

  const updatePinState = async (newState) => {
    const newPinnedObject = {
      ...pinnedObject,
      PROJ: newState,
    };

    const pinString = Object.entries(newPinnedObject)
      .map(([key, value]) => `${key}:${value}`)
      .join("|");

    const config = {
      method: "put",
      url: `${BaseURL}/api/v1/users/${localStorage.getItem(
        "userid"
      )}/edit-user`,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify({ pin: pinString }),
    };

    try {
      const response = await axios.request(config);
      fetchUserDetails();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const newState = Object.keys(pinStates).find(
      (key) => pinStates[key] === true
    );

    if (newState) {
      const newStateValue = newState === "All Projects" ? "ALL" : "RV";

      updatePinState(newStateValue)
        .then(() => {
        })
        .catch((error) => {
          console.error("Failed to update pin state:", error);
        });
    }
  }, [pinStates]);

  useEffect(() => {
    getSelectedTab(selectedTab);
    setCurrentPageProjects(1)
  }, [selectedTab])

  const handleUploadProject = async (values) => {
    const apiUrl = `${BaseURL}/api/v1/projects/${localStorage.getItem("userid")}/${values.companyId}/projects-upload`;

    const data = {
      companyId: values.companyId,
      projects: values.file,
    };
    toast.loading("Uploading projects sheet....");
    try {
      const tokens = localStorage.getItem('tokens');
      const token_obj = JSON.parse(tokens);
      const response = await axios.post(apiUrl, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${token_obj?.accessToken}`
        }
      });
      handleProjectsUploadClose();
      fetchProjectsSheets();
      toast.dismiss();
      toast.success(response?.data?.message || "Projects uploaded successfully");
    } catch (error) {
      console.error(error);
      toast.dismiss();
      toast.error(error?.response?.data?.message || "Failed to upload projects sheet.");
    }
  };

  const handleFilterClick = () => {
    setFilterClicked(!filterClicked);
    setFilterPanelOpen(!filterPanelOpen);
    setFilterPanelOpen(!filterPanelOpen);
  };

  const handleFilterPanelClose = () => {
    setFilterPanelOpen(false);
    setTimeout(() => {
      setFilterPanelOpen(false);
      setFilterClicked(false);
    }, 0);
  };
  const countActiveFilters = () => {
    let count = 0;
    const storedFilters = JSON.parse(localStorage.getItem("projectFilters")) || projectFilterState;

    // if (storedFilters?.company?.length > 0) count += 1;
    // if (storedFilters?.accYear?.length > 0) count += 1;

    if (projectFilterState?.company?.length > 0) count += 1;
    if (projectFilterState?.fiscalYear?.length > 0) count += 1;

    if (Array.isArray(storedFilters?.spocName)) {
      if (storedFilters.spocName.some(spocName => spocName?.trim() !== "")) { count += 1; }
    }
    if (Array.isArray(storedFilters?.spocEmail)) {
      if (storedFilters.spocEmail.some(spocEmail => spocEmail?.trim() !== "")) { count += 1; }
    }

    if (Array.isArray(storedFilters?.dataGathering)) {
      if (storedFilters.dataGathering.some(dataGathering => dataGathering?.trim() !== "")) { count += 1; }
    }
    if (Array.isArray(storedFilters?.projectStatus)) {
      if (storedFilters.projectStatus.some(projectStatus => projectStatus?.trim() !== "")) { count += 1; }
    }
    if (Array.isArray(storedFilters?.surveyStatus)) {
      if (storedFilters.surveyStatus.some(surveyStatus => surveyStatus?.trim() !== "")) { count += 1; }
    }
    if (Array.isArray(storedFilters?.timesheetStatus)) {
      if (storedFilters.timesheetStatus.some(timesheetStatus => timesheetStatus?.trim() !== "")) { count += 1; }
    }
    if (Array.isArray(storedFilters?.fteCostStatus)) {
      if (storedFilters.fteCostStatus.some(fteCostStatus => fteCostStatus?.trim() !== "")) { count += 1; }
    }
    if (Array.isArray(storedFilters?.subconCostStatus)) {
      if (storedFilters.subconCostStatus.some(subconCostStatus => subconCostStatus?.trim() !== "")) { count += 1; }
    }
    if (Array.isArray(storedFilters?.technicalInterviewStatus)) {
      if (storedFilters.technicalInterviewStatus.some(technicalInterviewStatus => technicalInterviewStatus?.trim() !== "")) { count += 1; }
    }


    if (Array.isArray(storedFilters?.technicalSummaryStatus)) {
      if (storedFilters.technicalSummaryStatus.some(technicalSummaryStatus => technicalSummaryStatus?.trim() !== "")) { count += 1; }
    }

    if (Array.isArray(storedFilters?.financialSummaryStatus)) {
      if (storedFilters.financialSummaryStatus.some(financialSummaryStatus => financialSummaryStatus?.trim() !== "")) { count += 1; }
    }

    if (Array.isArray(storedFilters?.claimsFormstatus)) {
      if (storedFilters.claimsFormstatus.some(claimsFormstatus => claimsFormstatus?.trim() !== "")) { count += 1; }
    }

    if (Array.isArray(storedFilters?.finalReviewStatus)) {
      if (storedFilters.finalReviewStatus.some(finalReviewStatus => finalReviewStatus?.trim() !== "")) { count += 1; }
    }

    if (Array.isArray(storedFilters?.lastUpdateBy)) {
      if (storedFilters.lastUpdateBy.some(lastUpdateBy => lastUpdateBy?.trim() !== "")) { count += 1; }
    }
    if (Array.isArray(storedFilters?.s_fte_cost)) {
      if (storedFilters.s_fte_cost.some(expense => expense > 0)) { count += 1; }
    }
    if (Array.isArray(storedFilters?.s_subcon_cost)) {
      if (storedFilters.s_subcon_cost.some(expense => expense > 0)) { count += 1; }
    }
    if (Array.isArray(storedFilters?.s_total_project_cost)) {
      if (storedFilters.s_total_project_cost.some(expense => expense > 0)) { count += 1; }
    }
    if (Array.isArray(storedFilters?.s_fte_qre_cost)) {
      if (storedFilters.s_fte_qre_cost.some(expense => expense > 0)) { count += 1; }
    }
    if (Array.isArray(storedFilters?.s_subcon_qre_cost)) {
      if (storedFilters.s_subcon_qre_cost.some(expense => expense > 0)) { count += 1; }
    }
    if (Array.isArray(storedFilters?.s_qre_cost)) {
      if (storedFilters.s_qre_cost.some(expense => expense > 0)) { count += 1; }
    }
    if (Array.isArray(storedFilters?.s_fte_hours)) {
      if (storedFilters.s_fte_hours.some(expense => expense > 0)) { count += 1; }
    }
    if (Array.isArray(storedFilters?.s_subcon_hours)) {
      if (storedFilters.s_subcon_hours.some(expense => expense > 0)) { count += 1; }
    }
    if (Array.isArray(storedFilters?.s_total_hours)) {
      if (storedFilters.s_total_hours.some(expense => expense > 0)) { count += 1; }
    }
    if (Array.isArray(storedFilters?.s_rnd_adjustment)) {
      if (storedFilters.s_rnd_adjustment.some(expense => expense > 0)) { count += 1; }
    }
    if (Array.isArray(storedFilters?.rndFinal)) {
      if (storedFilters.rndFinal.some(expense => expense > 0)) { count += 1; }
    }
    if (Array.isArray(storedFilters?.s_rd_credits)) {
      if (storedFilters.s_rd_credits.some(expense => expense > 0)) { count += 1; }
    }
    if (Array.isArray(storedFilters?.rndPotential)) {
      if (storedFilters.rndPotential.some(potential => potential > 0)) { count += 1; }
    }
    return count;
  };

  const updateFilters = (newFilters) => {
    setProjectFilterState(newFilters);
    const count = countActiveFilters(newFilters);
    setActiveFilterCount(count);

    localStorage.setItem("projectFilters", JSON.stringify(newFilters));
  };


  return (
    <>
      {filterPanelOpen && <div style={styleConstants.overlay} />}
      <Box
        sx={{
          opacity: filterPanelOpen ? 15 : 1,
          transition: 'opacity 0.3s ease-in-out',
        }}
      >
        <Box>
          <DownloadModalForm open={showDownloadModal} tableColumn={tableData} handleClose={handleCloseDownloadModal} projects={projects} company={clientData} fetchProjects={fetchProjects} />
        </Box >
        <Box>
          <SpocModalForm open={showSendUpdates} tableColumn={tableData} handleClose={handleShowSendUpdates} handleSendMail={handleSendMail} handleSurveysMailOpen={handleShowSendUpdates} handleConfirmationModalOpen={handleConfirmationModalOpen} updateData={updateData} updatePurpose={updatePurpose} projects={projects} handleUpdateSpockData={handleUpdateSpockData} fetchProjects={fetchProjects} />
        </Box >
        <TableIntro
          heading={
            pinnedObject?.PROJ === "RV" ? "Recently Viewed" : "All Projects"
          }
          btnName={"Project"}
          btnName2={"SPOC"}
          btnNameD={""}
          btnName3={"Upload"}
          page={"project"}
          data={projects}
          totalItems={filteredRows?.length || 0}
          currentPage={currentPageProjects}
          itemsPerPage={itemsPerPage}
          onUploadClick={handleUploadClick}
          onUploadClick2={handleSendUpdatesClick}
          onUploadClick3={handleProjectsUploadClick}
          onDownloadClick={handleDownloadClick}
          onSearch={handleSearch}
          items={["All Projects", "Recently Viewed"]}
          latestUpdateTime={latestUpdateTime}
          onApplyFilters={applyFiltersAndFetch}
          appliedFilters={appliedFilters}
          createPermission={useHasAccessToFeature("F013", "P000000007")}
          searchPermission={useHasAccessToFeature("F013", "P000000009")}
          onSelectedItem={handleSelectedHeaderItem}
          isPinnedState={pinStates[currentState]}
          onPinClicked={() => togglePinState(currentState)}
          projectNavs={projectNavs}
          handleSelectedTab={handleSelectedTab}
          selectedTab={selectedTab}
          totalSheetsNumber={projectsSheets?.length}
          countActiveFilters={countActiveFilters}
        />
        <ProjectsAddModal
          open={showAddProjectsModal}
          handleClose={handleProjectsUploadClose}
          handleSubmit={handleUploadProject}
          type={"upload"}
        />
        <ProjectModal
          open={modalOpen}
          handleClose={handleModalClose}
          fetchProjectData={fetchProjects}
        />
        <CustomPagination
          currentPage={currentPageProjects}
          totalPages={selectedTab === "All Projects" ? totalPagesProjects : totalPagesSheets}
          changePage={handleChangePage}
          changeItemsPerPage={handleChangeItemsPerPage}
          minRows={20}
        />
        <Box sx={{ display: "flex", pt: 0, pb: page === "activity" ? -1 : 0 }}>
          <Box sx={{ marginLeft: "9px", marginTop: "-120px", display: "flex", alignItems: "center" }}>
            {!(page === "alerts") && (
              <Badge
                badgeContent={countActiveFilters()}
                color="error"
                overlap="circular"
                sx={{
                  zIndex: 2,
                  marginRight: "0px",
                  '& .MuiBadge-badge': {
                    minWidth: '10px',
                    height: '16px',
                    fontSize: '10px',
                    paddingLeft: '5',
                    transform: 'translate(25%, -25%)',
                    backgroundColor: '#FD5707',
                  },
                }}
              >
                <HiFilter
                  style={styleConstants.filterDownloadStyle}
                  onClick={handleFilterClick}
                />
              </Badge>
            )}
          </Box>
          <Drawer
            anchor="left"
            open={filterPanelOpen}
            onClose={handleFilterPanelClose}
            sx={{
              width: '300px',
              flexShrink: 0,
            }}
            variant="persistent"
          >
            {filterPanelOpen && (
              <ProjectsFilters
                handleClose={handleFilterPanelClose}
                open={filterPanelOpen}
                page={page}
                documentType={documentType}
                onApplyFilters={onApplyFilters}
                countActiveFilters={countActiveFilters}
                style={{ position: 'absolute', left: 0 }}
              />
            )}
          </Drawer>
        </Box>
        {/** Add the projects upload modal Open here **/}
        <Box
          sx={{
            flexGrow: 1,
            marginLeft: filterPanelOpen ? '300px' : '0',
            // maxHeight: "82vh",
            // overflowY: "auto",
          }}
        >
          {selectedTab === "All Projects" && <TableContainer
            sx={{
              maxHeight: "72vh",
              // overflowY: "auto",
              borderTopLeftRadius: "10px",
            }}>

            <Table sx={{ minWidth: 650, borderBottomLeftRadius: "20px", }} aria-label="simple table">
              <TableHeader tableData={tableData} page="projects" />
              {!loading && <ProjectsTableBody
                data={currentData}
                currentPage={currentPageProjects}
                itemsPerPage={itemsPerPage}
                projectId={projectId}
                fetchProjects={fetchProjects}
                editFields={editFields}
              />
              }
            </Table>
            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                  minHeight: "380px",
                }}
              >
                <CircularProgress sx={{ color: "#00A398" }} />
              </div>
            )}

            {currentData?.length === 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                  minHeight: "380px",
                }}
              >
                No projects found.
              </div>
            )}
          </TableContainer>}
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            marginLeft: filterPanelOpen ? '300px' : '0',
            // maxHeight: "82vh",
            // overflowY: "auto",
          }}
        >
          {selectedTab === "Uploaded Sheets" && <SheetsListing
            sx={{
              maxHeight: "82vh",
              overflowY: "auto",
              borderTopLeftRadius: "20px",
            }}
            page={"projects"} projectsSheets={projectsSheets} itemsPerPage={itemsPerPage} />}
        </Box>
      </Box>
      {/* <CustomPagination
        currentPage={currentPageProjects}
        totalPages={selectedTab === "All Projects" ? totalPagesProjects : totalPagesSheets}
        changePage={handleChangePage}
        changeItemsPerPage={handleChangeItemsPerPage}
        minRows={20}
      /> */}
      <Toaster />
    </>
  );
}

export default ProjectsTableStack;